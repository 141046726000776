"use client"
import { Card, LineChart } from "@tremor/react"
import { format } from "date-fns"
import { DAY_LENGTH } from "@/utils/constants"
import { getDate } from "@/utils/dateUtils"

const data = [
  { date: getDate(63), weight: 185 },
  { date: getDate(56), weight: 190 },
  { date: getDate(49), weight: 195 },
  { date: getDate(42), weight: 205 },
  { date: getDate(35), weight: 205 },
  { date: getDate(28), weight: 210 },
  { date: getDate(21), weight: 205 },
  { date: getDate(14), weight: 215 },
  { date: getDate(7), weight: 220 },
  { date: getDate(0), weight: 225 },
]

const LandingPageAnalyticsGraph = () => {
  return (
    <Card decorationColor="zinc">
      <LineChart
        data={data}
        categories={["weight"]}
        index={"date"}
        showLegend={false}
        colors={["pink"]}
        valueFormatter={(value) => `${value}lbs`}
      />
    </Card>
  )
}

export default LandingPageAnalyticsGraph
