import { ReactNode, useEffect, useRef } from "react"
import Link from "next/link"
import { signOut } from "next-auth/react"
import { CloseButton } from "./svg/CloseButton"
import { bezier } from "@/utils/constants"
import { useClickOutside } from "@/hooks/useClickOutside"
import { Session } from "next-auth"

type NavMenuProps = {
  close: () => void
  isOpen: boolean
  session: Session | null
}

type NavMenuItemProps = {
  href: string
  children: ReactNode
  closeMenu: () => void
}

const NavMenuItem = ({ href, children, closeMenu }: NavMenuItemProps) => (
  <li className="border-b-2 border-zinc-800">
    <Link
      href={href}
      onClick={closeMenu}
      className="py-4 hover:text-pink-500 w-full transition block"
    >
      {children}
    </Link>
  </li>
)

const NavMenu = ({ close, isOpen, session }: NavMenuProps) => {
  const navMenuRef = useRef<HTMLDivElement>(null)
  useClickOutside(navMenuRef, close)

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto"
  }, [isOpen])

  return (
    <div
      ref={navMenuRef}
      className={`bg-gradient-to-b from-pink-600 to-blue-600 shadow-md fixed top-0 ${
        isOpen ? "translate-x-0" : "translate-x-[100vw]"
      } right-0 transition duration-300 ${bezier} w-full md:max-w-xl h-[100svh] overflow-auto flex flex-col items-center justify-center pl-1 z-[150]`}
    >
      <div className="bg-black w-full h-full container flex flex-col items-center justify-center relative">
        <button
          aria-label="Close"
          onClick={close}
          className="absolute top-4 right-4 focus:outline-none"
        >
          <CloseButton />
        </button>
        <ul className="text-xl text-white text-center font-bold child:border-b-2 child:border-zinc-800">
          <NavMenuItem closeMenu={close} href="/workouts">
            Workouts
          </NavMenuItem>
          <NavMenuItem closeMenu={close} href="/analytics">
            Analytics
          </NavMenuItem>
          <NavMenuItem closeMenu={close} href={`/${session?.user.username}`}>
            Profile
          </NavMenuItem>
          <NavMenuItem closeMenu={close} href="/create-plan">
            Create a plan
          </NavMenuItem>
          <NavMenuItem closeMenu={close} href="/settings">
            Settings
          </NavMenuItem>
          <li>
            <button
              aria-label="Sign out"
              onClick={() => signOut()}
              className="py-4 hover:text-pink-500 w-full transition"
            >
              Sign Out
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default NavMenu
