"use client"
import { memo, useEffect, useState } from "react"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { Workout } from "@/models/workout"
import { formatDate } from "@/utils/dateUtils"
import { Text } from "../Text"

type Predicate = (set: { warmup: boolean; failure: boolean }) => boolean

const calculateTotal = (workout: Workout, predicate: Predicate) => {
  return workout.exercises
    .map((w) => w.sets.filter(predicate).length)
    .reduce((a, b) => a + b, 0)
}

export const WorkoutStat = ({
  value,
  title,
}: {
  value: number
  title: string
}) => (
  <li className="bg-zinc-700 p-2 rounded-xl flex-grow text-center">
    <span className="font-bold block text-center">{value}</span>{" "}
    <span className="text-sm">{title}</span>
  </li>
)

const WorkoutCard = ({
  workout,
  isPublic = false,
}: {
  workout: Workout
  isPublic?: boolean
}) => {
  const [isMounted, setIsMounted] = useState(false)
  const pathname = usePathname()
  const totalSetsExcludingWarmups = calculateTotal(workout, (s) => !s.warmup)
  const totalSetsWithFailure = calculateTotal(workout, (s) => s.failure)
  const totalMuscleGroups = workout.exercises
    .map((w) => w.muscleGroups)
    .filter((m) => m !== null || m !== undefined).length

  useEffect(() => {
    setIsMounted(true)
  }, [isMounted])

  return (
    <li
      className="w-full flex items-center justify-center gap-2 relative max-w-xl group hover:cursor-pointer"
      key={workout.id}
    >
      <div className="w-full h-full flex justify-between items-center bg-transparent border-2 border-zinc-600 group-hover:border-zinc-400 bg-zinc-900 rounded-xl relative">
        <Link
          href={
            isPublic
              ? `${pathname}/workouts/${workout.id}`
              : `/workouts/${workout.id}`
          }
          className="w-full h-full bg-transparent flex flex-col items-start justify-center p-2 rounded-xl z-20"
        >
          <div className={`w-full flex justify-between items-center mb-2 px-1`}>
            <p className="text-lg font-bold">{workout.name}</p>
            <Text>{isMounted ? formatDate(new Date(workout.date)) : ""}</Text>
          </div>
          <ul className="w-full grid grid-cols-2 md:grid-cols-4 gap-2">
            <WorkoutStat
              value={workout.exercises.length}
              title={`exercise${workout.exercises.length === 1 ? "" : "s"}`}
            />

            <WorkoutStat
              value={totalSetsExcludingWarmups}
              title={`set${totalSetsExcludingWarmups === 1 ? "" : "s"}`}
            />

            <WorkoutStat
              value={totalMuscleGroups}
              title={`muscle${totalMuscleGroups === 1 ? "" : "s"}`}
            />

            <WorkoutStat
              value={totalSetsWithFailure}
              title={`failure${totalSetsWithFailure === 1 ? "" : "s"}`}
            />
          </ul>
        </Link>
      </div>
    </li>
  )
}

export default memo(WorkoutCard)
