import { format } from "date-fns"
import { DAY_LENGTH } from "./constants"

export const formatDate = (date: Date) => {
  const today = new Date()
  const workoutDateIsToday = date.getUTCDate() === today.getUTCDate()
  const formattedDate = workoutDateIsToday
    ? `Today -
                  ${format(new Date(date), "h:mm a")}`
    : format(new Date(date), "MMM d, yyyy - h:mm a")

  return formattedDate
}

export const getDate = (daysAgo: number, dateFormat: string = "MM/dd/yyyy") => {
  return format(new Date(Date.now() - DAY_LENGTH * daysAgo), dateFormat)
}
