"use client"
import { useState } from "react"
import { usePathname } from "next/navigation"
import Image from "next/image"
import Link from "next/link"
import { Session } from "next-auth"
import NavMenu from "./NavMenu"
import { Logo } from "./svg/logo"
import PrimaryLink from "./Link"

type NavMenuButtonProps = {
  session: Session | null
  openMenu: () => void
}

export const NavMenuButton = ({ session, openMenu }: NavMenuButtonProps) => {
  if (!session) return null
  return (
    <div className="flex items-center justify-center">
      {session.user.role === "admin" && (
        <Link
          className="text-white hover:text-pink-500 transition mr-4 !p-2 !text-sm"
          href="/admin"
        >
          Admin
        </Link>
      )}
      <button
        aria-label="Open navigation menu"
        onClick={openMenu}
        className="bg-gradient-to-b from-pink-600 to-blue-600 transition h-12 w-12 rounded-full relative focus:outline-none p-[2px] hover:p-1 active:p-[0.30rem]"
      >
        {session?.user?.image && (
          <Image
            fill
            className="user-select-none rounded-full !relative"
            src={session?.user.image as string}
            alt="User"
          />
        )}
      </button>
    </div>
  )
}

const Navbar = ({ session }: { session: Session | null }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const currentRoute = usePathname()
  const isHome = currentRoute === "/"

  const showMainNavRoutes = [
    "/workouts",
    "/settings",
    "/admin",
    "/analytics",
    "/",
    "/workouts/[id]",
    `/workouts/${currentRoute.split("/")?.[2]}`,
    "/create-plan",
    "/terms",
    "/privacy",
    "/support",
  ]

  const showMainNav = showMainNavRoutes.includes(currentRoute)

  return (
    <nav
      className={`${isHome ? "hidden" : "bg-black"} ${
        showMainNav
          ? "bg-black border-b-2 border-b-zinc-600 shadow-md"
          : "lg:bg-transparent py-2"
      } fixed top-0 left-0 w-full flex items-center justify-center z-[100] `}
    >
      <div
        className={`w-full md:container flex ${
          showMainNav ? "justify-between" : "justify-end"
        } items-center p-2 relative`}
      >
        <Link href="/" className={showMainNav ? "" : "hidden"}>
          <Logo className="fill-white w-14 h-14" full />
        </Link>

        <NavMenuButton session={session} openMenu={handleMenuOpen} />
        {!session && (
          <PrimaryLink className="!p-2 text-sm" href="/auth/signin">
            Sign In
          </PrimaryLink>
        )}
      </div>
      {session && (
        <NavMenu
          session={session}
          close={() => setIsMenuOpen(false)}
          isOpen={isMenuOpen}
        />
      )}
    </nav>
  )
}

export default Navbar
