"use client"
import { BuiltInProviderType } from "next-auth/providers"
import { ClientSafeProvider, LiteralUnion, signIn } from "next-auth/react"
import { useState } from "react"
import "@/styles/header.css"

type ButtonProps = {
  children: React.ReactNode
  handleClick?: () => void
  className?: string
  type?: "button" | "submit" | "reset"
  disabled?: boolean
  title?: string
  isSignIn?: boolean
  providerId?: string
  label?: string
}

type SignInButtonProps = {
  className?: string
  isPrimary?: boolean
  callback?: string
  buttonText?: string
  providers: Record<
    LiteralUnion<BuiltInProviderType, string>,
    ClientSafeProvider
  > | null
}

export const SignInButton = ({
  providers,
  className,
  isPrimary,
  callback,
  buttonText,
}: SignInButtonProps) => {
  const [signInToggled, setSignInToggled] = useState(false)

  return (
    <>
      {signInToggled ? (
        <>
          <div className="w-full max-w-sm bg-zinc-900 border-2 border-zinc-400 shadow-md rounded-2xl flex flex-col items-center justify-center p-4 gap-2">
            <h1 className="mb-1 text-sm text-zinc-300">Sign in with</h1>
            {providers &&
              Object.values(providers).map((provider: ClientSafeProvider) => {
                return (
                  <SecondaryButton
                    label={`Sign in with ${provider.name}`}
                    key={provider.name}
                    handleClick={() =>
                      signIn(provider.id, { callbackUrl: callback })
                    }
                    className={`w-full relative flex items-center justify-center ${
                      className || ""
                    }`}
                  >
                    <span className="block px-2">
                      <img
                        src={`/${provider.name.toLowerCase()}.svg`}
                        alt="Google Logo"
                        className="w-6 h-6"
                      />
                    </span>
                    <span className="block flex-grow -translate-x-5">
                      {provider.name}
                    </span>
                  </SecondaryButton>
                )
              })}
          </div>
        </>
      ) : isPrimary ? (
        <button
          aria-label="Sign in to Bulk Buddy"
          title="Sign in to Bulk Buddy"
          className="mb-10"
          onClick={() => setSignInToggled(true)}
        >
          <div className="w-full h-full">
            <div className="relative bg-white px-[2px] py-[2px] h-full w-full cursor-pointer duration-200 flex flex-row items-center justify-center font-[500] gap-x-2 text-black rounded-2xl group">
              <div className="lift-animated-border-btn h-full w-full rounded-2xl" />
              <div className="track-animated-border-btn h-full w-full rounded-2xl" />
              <div className="grow-animated-border-btn h-full w-full rounded-2xl" />
              <div className="animated-shadow-border-lift" />
              <div className="animated-shadow-border-track" />
              <div className="animated-shadow-border-grow" />
              <div className="font-bold flex w-full flex-row justify-center items-center gap-2 z-20 duration-200 hover:bg-transparent hover:text-black px-4 py-4 rounded-2xl text-white text-xl bg-black">
                Sign in to get started
                <span className="block translate-x-0 group-hover:translate-x-[5px] transition">
                  👉
                </span>
              </div>
            </div>
          </div>
        </button>
      ) : (
        <button
          aria-label="Sign in to Bulk Buddy"
          title="Sign in to Bulk Buddy"
          className="relative flex items-center justify-center bg-transparent border-2 border-white shadow-lg rounded-2xl p-4 text-lg font-bold transition overflow-hidden group"
          onClick={() => setSignInToggled(true)}
        >
          <div className="z-0 absolute top-0 left-0 w-[200%] translate-x-0 group-hover:-translate-x-[50%] h-full bg-gradient-to-l from-pink-600 to-blue-600 transition" />
          {/* right arrow svg icon */}
          <span className="z-10 flex items-center justify-center gap-2">
            <span className="block">{buttonText} </span>
            <span className="block translate-x-0 group-hover:translate-x-[5px] transition">
              👉
            </span>
          </span>
        </button>
      )}
    </>
  )
}

export const PrimaryButton = ({
  children,
  handleClick,
  className,
  type,
  disabled,
  title,
  label,
}: ButtonProps) => {
  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      title={title || ""}
      aria-label={label || ""}
      type={type || "button"}
      className={`p-4 bg-pink-600 hover:bg-pink-700 transition shadow-md hover:shadow-sm text-white text-lg font-bold rounded-2xl border-2 border-white disabled:bg-zinc-700 ${
        className ? className : ""
      }`}
    >
      {children}
    </button>
  )
}

export const SecondaryButton = ({
  children,
  handleClick,
  className,
  type,
  disabled,
  isSignIn,
  providerId,
  label,
}: ButtonProps) => {
  return (
    <button
      onClick={isSignIn ? () => signIn(providerId) : handleClick}
      type={type || "button"}
      aria-label={label || ""}
      disabled={disabled}
      className={`p-2 bg-zinc-700 hover:bg-zinc-800 transition shadow-md hover:shadow-sm text-white text-lg font-bold rounded-2xl border-2 border-zinc-400 ${className}`}
    >
      {children}
    </button>
  )
}
