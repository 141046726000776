import Link from "next/link"
import { ReactNode } from "react"

type PrimaryLinkProps = {
  className?: string
  href: string
  title?: string
  children: ReactNode
}

const PrimaryLink = ({
  className,
  href,
  title,
  children,
}: PrimaryLinkProps) => {
  return (
    <Link
      href={href}
      title={title || ""}
      className={`flex items-center justify-center gap-2 p-4 bg-pink-600 outline:none hover:bg-pink-700 focus:bg-pink-700 shadow-md transition border-2 border-white rounded-xl text-white text-lg font-bold group ${
        className || ""
      }`}
    >
      {children}
    </Link>
  )
}

export default PrimaryLink
